// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$seller-portal-ui-primary: mat.define-palette(mat.$indigo-palette);
$seller-portal-ui-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$seller-portal-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$seller-portal-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $seller-portal-ui-primary,
      accent: $seller-portal-ui-accent,
      warn: $seller-portal-ui-warn,
    ),
  )
);
$my-custom-typography: mat.define-legacy-typography-config(
  $headline:
    mat.define-typography-level(
      $font-family: Cairo,
      $font-weight: 700,
      $font-size: 1.875rem,
      $line-height: 2.25rem,
      $letter-spacing: normal,
    ),
  $title:
    mat.define-typography-level(
      $font-family: Cairo,
      $font-weight: 700,
      $font-size: 1.25rem,
      $line-height: 1.75rem,
      $letter-spacing: normal,
    ),
  $subheading-2:
    mat.define-typography-level(
      $font-family: Cairo,
      $font-weight: 700,
      $font-size: 1.125rem,
      $line-height: 1.75rem,
      $letter-spacing: normal,
    ),
  $subheading-1:
    mat.define-typography-level(
      $font-family: Cairo,
      $font-weight: 600,
      $font-size: 1rem,
      $line-height: 1.5rem,
      $letter-spacing: normal,
    ),
  $body-1:
    mat.define-typography-level(
      $font-family: Cairo,
      $font-weight: 500,
      $font-size: 0.875rem,
      $line-height: 1.5rem,
      $letter-spacing: normal,
    ),
);
h1 {
  color: #4a4d4e;
}
h3 {
  color: rgba(251, 27, 28, 1);
}
h4 {
  color: #777777;
}
p {
  color: #777777;
}
// rich text styles at product details
.ck-sticky-panel__content{
  display: none;

}
.ck-rounded-corners{
  padding: 0!important;
  border: 0!important;
  overflow: hidden!important;
}

.mat-body p, .mat-body-1 p, .mat-typography p{
  margin: 0 !important;
}
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($seller-portal-ui-theme);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($my-custom-typography);`
@include mat.all-legacy-component-typographies($my-custom-typography);
@include mat.legacy-core();

//import tailwind css
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Cairo";
  src: local("Cairo"), url("assets/fonts/Cairo-Bold.ttf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo";
  src: local("Cairo"), url("assets/fonts/Cairo-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cairo";
  src: local("Cairo"), url("assets/fonts/Cairo-Light.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cairo";
  src: local("Cairo"), url("assets/fonts/Cairo-SemiBold.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* You can add global styles to this file, and also import other style files */
* {
  direction: rtl !important;
  font-family: "Cairo";
}

mat-icon {
  font: Roboto !important ;
}

.primary-application-color {
  color: #fb1b1c;
}
.primary-application-background-color {
  background-color: #fb1b1c;
}
.secondary-application-color {
  color: white;
}
.light-gray {
  color: #777777;
}

html,
body {
  height: 100%;
  background-color: white;
}

/* application backgroud color as angular mat side nav overwrites defined background */
.mat-drawer-container {
  background-color: #ebebeb !important;
}

.blue {
  background-color: rgba(1, 102, 192, 0.08);
  color: #0166c0;
}

.red {
  background-color: rgba(251, 27, 28, 0.08);
  color: #fb1b1c;
}

.green {
  background-color: rgba(61, 204, 121, 0.08);
  color: #3dcc79;
}

.dark-green {
  background-color: rgba(20, 85, 87, 0.1);
  color: #145557;
}

.orange {
  background-color: rgba(251, 94, 7, 0.08);
  color: rgba(251, 94, 7, 1);
}

.dark-font-style {
  color: #4a4d4e;
  font-weight: 700;
}

.black-semi-bold-font-style {
  color: #000000;
  font-weight: 500;
}



.light-font-style {
  color: #777777;
  font-weight: 500;
}


.blue-font {
  color: rgba(49, 46, 220, 1);
}

.product-info-wrapper-style {
  border-radius: 10px;
  min-height: 30px;
}

.blue-background {
  background-color: rgba(49, 46, 220, 0.08);
}

.gray-background {
  background-color: rgba(235, 235, 235, 0.5);
}

.red-background {
  background-color: rgba(251, 27, 28, 0.08);
}

//input fields style
.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 6px !important;
}

label {
  color: #777777;
  font-weight: 700;
}
//margin between form field and label
.mat-form-field {
  margin-top: 15px;
  display: block !important;
}

.mat-error {
  margin-bottom: 2rem;
}

//drop down styles
.mat-select {
  font-family: "Cairo";
  font-weight: 600;
}

// Overwritting Buttons borders
.mat-focus-indicator.mat-raised-button.mat-button-base {
  border-radius: 6px;
}

.mat-focus-indicator.mat-stroked-button.mat-button-base {
  border-radius: 6px;
}

.mat-focus-indicator.mat-flat-button.mat-button-base {
  border-radius: 6px;
}

// Styling of Slide Toggle
// works best without disableRipple

.mat-form-field-outline-start,
.mat-form-field-outline-gap,
.mat-form-field-outline-end {
  background-color: white;
}

.mat-form-field-infix {
  border: none;
}

.mat-option {
  height: fit-content !important;
}
.mat-select-panel {
  margin-top: 30px !important;
  margin-right: -30px !important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  background-color: #fb5e07;
}

.mat-menu-panel {
  border-radius: 8px !important;
}

//Styling of Radio Button
.mat-radio-checked .mat-radio-inner-circle {
  background-color: #fb1b1c !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #fb1b1c!important;
}
// Styling of Mat Paginator
mat-paginator {
  border: 1px solid #e6e6eb;
}

//Success snackbar background-color
.success-snackbar {
  background-color: #d7eee3;

  mat-icon {
    color: #3dcc79;
  }
}

.fail-snackbar {
  background-color: #ffe7e5;

  mat-icon {
    color: #ff695b;
  }
}

// Modification to Mat Progress Bar
mat-progress-bar {
  background-color: #e6e6eb;
}

.mat-progress-bar .mat-progress-bar-background {
  display: none;
}

.mat-expansion-indicator::after {
  border-color: #fb5e07;
}

.error-panel .mat-dialog-container {
  border-radius: 10px;
}

.mat-sort-header-container {
  justify-content: center;
}

.showTable {
  padding-bottom: 10px;
}

.mat-drawer-side {
  border-left: none !important;
  border-right: none !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.custom-date-picker.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
  background-color: white !important;
}

.custom-date-picker input.mat-input-element {
  padding-inline-start: 8px;
}

.custom-date-picker .mat-form-field-infix {
  padding-bottom: 0px !important;
  margin: auto !important;
}

.custom-date-picker mat-datepicker-toggle {
  color: #fb1b1c !important;
}

.mat-calendar-body-selected {
  background-color: #fb1b1c !important;
}

.mat-calendar-body-cell-content:hover {
  background-color: #fb1b1b57 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: #e5e5e5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(251, 27, 28, 1);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(251, 27, 28, 1);
  border-radius: 10px;
}

// Global mattooltip style for seller portal
.seller-mattooltip{
  position: relative;
  top: 15px;
  right: 15px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FB1B1C, #FB1B1C), linear-gradient(0deg, #FB1B1C, #FB1B1C), linear-gradient(0deg, #C40001, #C40001), #C40001;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-transform: capitalize;
  color: #777777 !important;
  word-break: break-word !important;
  white-space: normal !important;
}

.customTooltip {
  min-width: 275px!important;
  text-align: right;
  color: #000000;
  border-radius: 20px!important;
}

.customTooltip .introjs-tooltipbuttons{
  padding: 5px 10px 14px;
  text-shadow:none;
  border-top: none;
}
.customTooltip .introjs-nextbutton:focus{
  color: #FFFFFF;
  background: #3DCC79;
  box-shadow: none;
  border: none;
}

.customTooltip .introjs-nextbutton{
  font-family: 'Cairo'!important;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  background: #3DCC79;
  border-radius: 20px;
  text-shadow:none;
}

.customTooltip .introjs-prevbutton{
  font-family: 'Cairo'!important;
  font-weight: 500;
  color:#777777;
  border-radius: 20px;
  background:linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FB1B1C, #FB1B1C), linear-gradient(0deg, #FB1B1C, #FB1B1C), linear-gradient(0deg, #C40001, #C40001), #C40001;
}

.customTooltip .introjs-tooltip-header{
  direction: ltr !important;
  padding-top: 5px;
  padding-left: 10px;
}

.customTooltip .introjs-tooltip-title{
  display: none;
}

.customTooltip .introjs-tooltiptext{
  font-family: 'Cairo'!important;
  font-weight: 500;
  padding: 10px;
  padding-bottom: 5px;
}

.customTooltip .introjs-bullets{
  padding: 0px;
}

.customTooltip .introjs-skipbutton{
  width: 23px;
  height: 23px;
  left: 8px;
  top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #000000;
  border-radius: 40px;
}

.customTooltip .introjs-arrow.right{
  top: 30px;
}
.customTooltip .introjs-arrow.bottom{
  left: 30px;
  bottom: -9px;
}
.customTooltip .introjs-arrow.top{
  left: 30px;
  top: -9px;
}
.introjs-helperLayer{
  box-shadow: rgb(33 33 33 / 0%) 0px 0px 1px 2px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px!important;
  max-height: -webkit-fill-available;
}
